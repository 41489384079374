if (window.FFF === undefined) {
    window.FFF = {}
}

var setScrollTop = window.DM && window.DM.molFeDomHelpers && window.DM.molFeDomHelpers.scrollUtils && window.DM.molFeDomHelpers.scrollUtils.setScrollTop;

window.FFF.addProductButton = function () {
    var helloAckReceived;
    var host = window.FFF.env.editorHost;

    var openEditorForImage = function () {
        var hashMatch = window.location.hash.match(/#(.*)/);

        if(hashMatch && window.FFF.getParameterFromUrl("openEditor") == 'y') {
            openAdmin(hashMatch[1], null);
        }
    };

    var onMouseScroll = function (event) {
        var delta = event.wheelDelta || -event.detail;
        if (setScrollTop) {
            setScrollTop(event.target, event.target.scrollTop + ( delta < 0 ? 1 : -1 ) * 30);
        }
        event.preventDefault();
    };

    var openEditor = function() {
        var editorIframe = document.querySelector('#fff-editor iframe');
        if (editorIframe) {
            var editorContentWindow = editorIframe.contentWindow;
            var attempts = 0;
            var ts = setInterval(function() {
                if (attempts++ >= 10 || helloAckReceived) {
                    clearInterval(ts);
                    helloAckReceived = false;
                    return;
                }
                editorContentWindow.postMessage({hello:true, fff:true}, host);
            }, 500);

            Array.from(document.querySelectorAll('.tinner iframe')).forEach(function (element) {
                element.addEventListener('mousewheel', onMouseScroll);
                element.addEventListener('DOMMouseScroll', onMouseScroll);
            });
        }
    };

    var closeEditor = function () {
        var editorIframe = document.querySelector('#fff-editor iframe');

        if (editorIframe) {
            var editorContentWindow = editorIframe.contentWindow;
            editorContentWindow.postMessage({close_editor: true, timeoutId: timeoutId, fff: true}, host);
        }

        // Will close Tiny box if the iframe is offline or not responding.
        var timeoutId = setTimeout(function () {
            TINY.box.closetbox();
        }, 2000);

    };

    window.addEventListener('message', function (event) {
        if (typeof event != 'object' || !event.data.fff) {
            return;
        }

        clearTimeout(event.data.timeoutId);

        if (event.data.closeWindow) {
            if (event.data.unsaved_changes) {
                var answer = confirm("You have unsaved changes which would be lost. Do you want to continue? \n Press cancel to abort.");
                if (answer) {
                    TINY.box.closetbox();
                }
            } else {
                TINY.box.closetbox();
            }
        }
        if(event.data.reload) {
            window.FFF.init();
        }
        if (event.data.helloAck) {
            helloAckReceived = true;
        }

    });

    var openAdmin = function (imageUrl, event) {
        if(event) event.stopPropagation();

        var parentDataForIframe = {
            host:window.location.host,
            hostname:window.location.hostname,
            href:window.location.href,
            pathname:window.location.pathname,
            port:window.location.ports,
            imageUrl:imageUrl,
            pageCriteriaGeo:window.FFF.getPageCriteriaGeo(),
            articleId:window.FFF.getPageCriteriaArticleId()
        };

        var iframeHeight = window.innerHeight - 80;
        var iframeUrl = host + '/admin/main?data=' + encodeURIComponent(JSON.stringify(parentDataForIframe));

        TINY.box.show({
            boxid:'fff-editor',
            iframe:iframeUrl,
            width:1300,
            height:iframeHeight,
            close:true,
            opacity:50,
            fixed:true,
            animate:true,
            openjs: openEditor,
            closejs:closeEditor
        });
    };

    Array.from(document.querySelectorAll('img.blkBorder')).forEach(function (element) {
        element.parentElement.classList.add('fff-admin');

        element.parentElement.insertAdjacentHTML('beforeend', '<div class="fff-control-box-hover"></div><a class="fff-control-box fff-control-add-product" href="javascript:void(0)">Add Product</a>');
    });

    Array.from(document.querySelectorAll('.fff-control-box-hover, .fff-control-box')).forEach(function (element) {
        element.addEventListener('mouseenter', function (event) {
            Array.from(event.target.parentElement.getElementsByClassName('fff-control-box')).forEach(function (element) {
                element.style.display = 'block';
            });
        });
        element.addEventListener('mouseleave', function (event) {
            Array.from(event.target.parentElement.getElementsByClassName('fff-control-box')).forEach(function (element) {
                element.style.display = 'none';
            });
        });
    });

    Array.from(document.getElementsByClassName('fff-control-add-product')).forEach(function (element) {
        element.addEventListener('click', function (event) {
            var fffAdmin = event.target.closest('.fff-admin');
            if (fffAdmin) {
                var imageElement = fffAdmin.querySelector('.blkBorder');
                if (imageElement) {
                    openAdmin(imageElement.getAttribute('src'), event);
                }
            }
        });
    });

    setTimeout(function () {
        openEditorForImage();
    }, 1000);

};
