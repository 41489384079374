var fff = window.FFF,
    resetScrollHack,
    currentTop,
    isAndroidBrowser42x = navigator.userAgent.search(/Android 4\.[23456789].*Chrome\/[1]/i) !== -1,
    stickyBanner,
    popup;
var scrollUtils = window.DM && window.DM.molFeDomHelpers && window.DM.molFeDomHelpers.scrollUtils;
var pageMetadata = DM.molFePageMetadata.getPageMetadata();

var displayIcon = function (fffDocument, Mustache, forceDisplayIcon) {
    var iconDisplayed = false;
    //We only want one FFF on mobile article pages. Either an inline or an overlay when there is no inline
    if(forceDisplayIcon) {
        var imageElement = getImageElement();

        if (imageElement) {
            iconDisplayed = attachFFFHoverIconClickHandler(addFFFHoverIconMarkup(getElement(imageElement)));
        }
    }
    return iconDisplayed;

    function getImageElement() {
        var deferredSrc = document.querySelector('img[data-src="' + fffDocument.url + '"]');
        return deferredSrc ? deferredSrc : document.querySelector('img[src="' + fffDocument.url + '"]');
    }

    function addFFFHoverIconMarkup(element) {
        var hoverIconElement = document.createElement('div');
        //IE 11 Does not support multiple parameters for the add() & remove() methods, see https://caniuse.com/#search=classList
        hoverIconElement.classList.add('fff-hover-icon');
        hoverIconElement.classList.add('fff-m-hover-icon');
        hoverIconElement.innerHTML = Mustache.render(fff.views.overlay, fffDocument);
        hoverIconElement.setAttribute('data-id', fffDocument.url);
        return element.insertAdjacentElement('afterend', hoverIconElement);
    }

    function attachFFFHoverIconClickHandler(element) {
        element.parentElement.querySelector('.fff-hover-icon').addEventListener('click', onFFFHoverIconClick);
        return element && element.length > 0;

        function onFFFHoverIconClick() {
            _displayPopup(fffDocument, fffDocument.url, Mustache);
        }
    }

    function getElement(image) {
        var element = image.parentElement.parentElement.querySelector('.fff-pic');
        if (element.nodeName === 'FIGURE') {
            element = image;
        }
        return element;
    }
};

var _displayPopup = function(fffDocument, id, Mustache) {
    if (!popup) {
        popup = document.createElement('div');
        popup.setAttribute('id', 'fff-popup');
        document.body.appendChild(popup)
    }
    if (!fff.templateCache[id]) {
        var accessoriseNativeAdHtml = '';
        var accessoriseNativeAd = document.getElementsByClassName('fff-accessorise');
        if(accessoriseNativeAd.length) {
            accessoriseNativeAdHtml = accessoriseNativeAd[0].innerHTML;
        }
        fff.templateCache[id] = Mustache.render(fff.views.popup, fff.decorateWithExtraInformation(fffDocument), {accessoriseNativeAd: accessoriseNativeAdHtml});
    }
    _showPopup(fffDocument, id);
};

var displayPopup = function(event, src, Mustache) {
    _displayPopup(fff.viewCache[src], src, Mustache);
};

var _showPopup = function(fffDocument, id) {

    if (!navigator.userAgent.match(/msie/i)) document.documentElement.classList.add('noscroll');
    if (scrollUtils.getScrollTop) {
        currentTop = scrollUtils.getScrollTop(document.documentElement);
    }
    /* TL;DR this is an hack for the Samsung S4 android browser (4.2.2) */
    /* Every android browser tested has a bug managing position fixed windows:
        They continue scrolling the window despite of overflow: hidden on
        html.
        The Samsung S4 has another nasty bug: it cannot detect correctly the
        coordinates of a touch event on a fixed box if the window below is scrolled.
        For these reasons I reset (for this browser only) the window scroll position.
        Without this the close button doesn't work.
        */
    if(isAndroidBrowser42x){
        resetScrollHack = setInterval(function (){
            var top;
            if (scrollUtils.getScrollTop) {
                top = scrollUtils.getScrollTop(document.documentElement);
            }
            if (top !== 0 && scrollUtils.scrollTo){
                scrollUtils.scrollTo(0, 0);
            }
        }, 800);
    }

    popup.innerHTML = fff.templateCache[id];
    setScrollTop(popup, 0);
    Array.from(popup.querySelectorAll('.fff-back, .fff-close')).forEach(function (element) {
        element.addEventListener('click', _closePopup);
    });
    popup.classList.add('active');

    if(!stickyBanner) stickyBanner = document.getElementById('stickyBanner');
    stickyBanner.classList.toggle('sticky-disabled');

    fff.trackFFFImpression(fffDocument,
        'click',
        'article',
        fffDocument.productTypes ? fffDocument.productTypes.sort().join(',') : '',
        fffDocument.trends ? fffDocument.trends.sort().join(',') : '',
        '');
    fff.bindCarouselTrackingEvents(fffDocument.url, '.fff-m-popup', 'article');
    fff.bindMainProductaAndPartnerLinksTrackingEvents(fffDocument.url, '.fff-m-popup', 'article');
};

var _closePopup = function() {
    if(isAndroidBrowser42x){
        clearInterval(resetScrollHack);
    }
    if (scrollUtils.scrollTo) {
        scrollUtils.scrollTo(0, currentTop);
    }
    stickyBanner.classList.toggle('sticky-disabled');
    if (!navigator.userAgent.match(/msie/i)) document.documentElement.classList.remove('noscroll');

    popup.classList.remove('active');
    fff.isOverlayOpen = false;
};

var expandMobileInline = function () {
    Array.from(document.getElementsByClassName('fff-read-more')).forEach(function (element) {
        element.classList.add('hidden');
    });
    Array.from(document.getElementsByClassName('fff-m-expandable')).forEach(function (element) {
        element.classList.remove('hidden');
    });
};

var hideAllInlinesAfterTheFirstOnMobile = function () {
    Array.prototype.slice.call(document.getElementsByClassName('fff-m-inline'), 1).forEach(function(fffInline) {
        fffInline.style.display = 'none';
    });
};

if (pageMetadata.renderPlatform === 'mobile')  {
    // mobile overrides
    fff.templateCache = fff.templateCache || {};
    fff.templatesPath = '/mobile/templates';
    fff.imgSelector = 'img[id^=i-]';
    fff.displayIcon = displayIcon;
    fff.displayPopup = displayPopup;
    fff.expandMobileInline = expandMobileInline;
    fff.hideAllInlinesAfterTheFirstOnMobile = hideAllInlinesAfterTheFirstOnMobile;
}
