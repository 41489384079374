FFFOverlayHelpers = {
    extendViewData: function (viewData) {
        viewData.expired = (new Date()).getTime() > viewData.msExpiryDate;

        viewData.getButtonText_FUNC = function () {
            return viewData.expired ? 'Visit site' : 'Buy now';
        };
        viewData.isPriceBadgeVisible_FUNC = function () {
            return (viewData.price && !viewData.expired) ? 'block' : 'none';
        };
        //remove &pound; from templates when moved to utf8
        viewData.getPrice_FUNC = function () {
            return viewData.price;
        };
        //remove &pound; from templates when moved to utf8
        viewData.getCurrency_FUNC = function () {
            var geo = window.FFF.getPageCriteriaGeo();
            return geo === 'us' || geo === 'au' ? '$' : '£';
        };
        viewData.isPriceMiniBadgeVisible_FUNC = function () {
            return !viewData.expired ? 'block' : 'none';
        };
        viewData.accessoryIndex = 0;
        viewData.showAccessorise_FUNC = function() {
            return viewData.hasAccessorise ? '' : 'hidden';
        };
        viewData.isAccessoryPriceVisible_FUNC_INDEX = function () {
            var i = Math.floor(viewData.accessoryIndex);
            return viewData.accessories[i].accessoryPrice ? 'inline-block' : 'none';
        };
        viewData.isBuyNowButtonVisible_FUNC = function () {
            return viewData.productUrl ? 'block' : 'none';
        };
        viewData.isAccessoryBuyNowButtonVisible_FUNC_INDEX = function () {
            var i = Math.floor(viewData.accessoryIndex);
            //This accessoryIndex is a hack, following same approach that on relatedIndex. It needs re thinking how to handle this.
            //Increment the index only in the last function called from the accessorise template part
            viewData.accessoryIndex++;
            return viewData.accessories[i].accessoryUrl ? 'block' : 'none';
        };
        viewData.isAccessoryUrlClickable_FUNC_INDEX = function () {
            var i = Math.floor(viewData.accessoryIndex);
            return viewData.accessories[i].accessoryUrl ? '' : 'fff-unclickable';
        };
        viewData.isAccessoryDottedLineVisible_FUNC_INDEX = function () {
            var i = Math.floor(viewData.accessoryIndex);
            return !viewData.accessories[i].accessoryUrl && !viewData.accessories[i].accessoryPrice ? 'none' : 'block';
        };
        viewData.getProductUrl_FUNC = function () {
            return viewData.expired ? viewData.spikedUrl : viewData.productUrl;
        };

        viewData.hasCroppedImage = testProperty(viewData, 'article.cropImages.overlay');
        viewData.hasNoCroppedImage = !viewData.hasCroppedImage;

        viewData.imageUnclickableBehaviour_FUNC = function() {
            if(!viewData.getProductUrl_FUNC()) {
                return 'return false;';
            }
            return '';
        };
        viewData.imageUnclickableClass_FUNC = function() {
            if(!viewData.getProductUrl_FUNC()) {
                return " fff-unclickable";
            }
            return '';
        };
        viewData.relatedIndex = 0;

        viewData.getRelatedProductUrl_FUNC_INDEX = function () {
            var i = Math.floor(viewData.relatedIndex / 2);
            viewData.relatedIndex++;
            return viewData.expired ? viewData.relatedProducts[i].spikedUrl : viewData.relatedProducts[i].relatedUrl;
        };

        viewData.croppedImagesPath = window.FFF.env.croppedImagesPath;
    },
    celebrityOrDefault: function(celebrity, fffData) {
        if(!celebrity) {return fffData.articleId;}
        return celebrity;
    },
    mainProductOrDefault: function(mainProduct) {
        if(!mainProduct) {return 'notavailable';}
        return mainProduct;
    },
};

function testProperty(obj, prop) {
    var parts = prop.split('.');
    for(var i = 0, l = parts.length; i < l; i++) {
        var part = parts[i];
        if(obj !== null && typeof obj === "object" && part in obj) {
            obj = obj[part];
        }
        else {
            return false;
        }
    }
    return true;
}

//do module.exports only for NodeJS, not in the front end as module is not defined there
if(typeof window === 'undefined') {
    module.exports = FFFOverlayHelpers;
}
